/* page content */
.page {
	.news1, .news {
		.date {
			color: #0080ff;
		}
		a {
			text-decoration: none;
            color: #0080ff;
			&:hover {
				color: #414141;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #0080ff;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #0080ff;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
    display: table;
    background-color: #fff;
	font-size: 18px;
    padding: 11px 35px 10px;
    margin: 30px auto 55px;
    line-height: 1;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
    .container {
        width: auto;
    }
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #818080;
			@include inline-block;
			a {
                font-size: 18px;
				text-decoration: none;
                &:hover {
                    color: #0069d1;
                }
			}
			&:after {
				content: '\f105';
                font-family: 'fontAwesome';
				margin: 0px 12px 0px 16px;
				color: #414141;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
    @media screen and (max-width: 1199px) {
        font-size: 16px;
        padding: 10px 20px; 
        margin: 20px auto 40px;
        ul li {
            &:after {
                margin: -1px 8px 0 12px;
            }
            a {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        font-size: 14px;
        padding: 10px 10px;
        ul li {
            &:after {
                margin: 0 5px 0 8px;
            }
            a {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        display: block;
        width: auto;
        padding: 10px 0;
        margin: 15px 15px 30px;
    }
}
/* breadcrumbs */

/* page catalog */

.cat {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    .cat__col {
        width: calc(50% - 40px);
        background-color: #fff;
        padding: 25px;
        margin: 20px 20px;
        &:nth-of-type(1),
        &:nth-of-type(2) {
            margin-top: 0;
        }
        h3 {
            font-size: 22px;
            transition: 300ms;
            margin: 0;
        }
        img {
            display: block;
            height: 220px;
            max-width: 100%;
            object-fit: contain;
            object-position: center center;
            margin: 0 auto;
        }
        &:hover {
            box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
            h3 {
                color: #0069d1;
            }
        }
    }
    @media screen and (max-width: 991px) {
        margin: 0 -10px;
        .cat__col {
            width: calc(50% - 20px);
            padding: 20px;
            margin: 10px;
            h3 {
                font-size: 18px;
                margin: 10px 0 0 0;
            }
            img {
                height: 160px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .cat__col {
            h3 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .cat__col {
            img {
                height: 130px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .cat__col {
            display: block;
            width: 100%;
            margin-bottom: 20px;
            img {
                height: 120px;
            }
        }
    }
}


.cat--inside {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .cat--inside__col {
        &:nth-of-type(-n+4) {
            margin-top: 0;
        }
    }
    @media screen and (max-width: 767px) {
        margin: 0 -10px;
    }
    @media screen and (max-width: 420px) {
        margin: 0;
    }
}
.cat--inside__col {
    width: calc(25% - 30px);
    height: 275px;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    padding: 20px;
    margin: 15px;
    .cat--inside__col--img {
        height: 150px;
        margin-top: 50px;
        img {
            display: block;
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
            object-position: center center;
            margin: 0 auto;
        }
    }
    .more {
        display: block;
        font-size: 16px;
        position: absolute;
        left: 20px;
        bottom: 15px;
        padding-right: 14px;
        transition: 300ms;
        &:after {
            content: '\f105';
            font-family: 'fontAwesome';
            font-size: 16px;
            position: absolute;
            right: 0;
            top: 2px;
            transition: 300ms;
        }
    }
    p {
        font-size: 16px;
        position: absolute;
        left: 20px;
        top: 20px;
        margin: 0;
    }
    &:hover {
        color: #414141;
        box-shadow: 0px 0px 12px rgba(0,0,0,0.2);
        .more {
            color: #0080ff;
            &:after {
                right: -3px;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        height: 250px;
        .cat--inside__col--img {
            height: 120px;
        }
    }
    @media screen and (max-width: 991px) {
        width: calc(33.33% - 20px);
        margin: 0 10px 20px;
    }
    @media screen and (max-width: 680px) {
        width: calc(50% - 20px);
    }
    @media screen and (max-width: 420px) {
        width: 100%;
        margin: 0 0 20px;
    }
}

.cat--unit {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    .cat--unit__left {
        width: 500px;
    }
    .cat--unit__right {
        width: calc(100% - 500px);
        padding-left: 35px;
        .btn {
            margin: 25px 0;
        }
    }
    .cat--unit__for {
        padding-bottom: 30px;
        background-color: #fff;
        .slick-slide {
            display: flex;
            height: 470px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            outline: none;
            padding: 10px;
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
        .slick-arrow {
            height: 30px;
            width: 20px;
            top: auto;
            bottom: 2px;
            transform: translate(0, 0);
            z-index: 1;
            &:before {
                display: none;
            }
            &:after {
                content: '\f104';
                font-family: 'fontAwesome';
                font-size: 30px;
                color: #414141;
                transition: 300ms;
            }
            &.slick-prev {
                left: 10px;
            }
            &.slick-next {
                right: 10px;
                &:after {
                    content: '\f105';
                }
            }
            &:hover:after {
                color: #0069d1;
            }
        }
    }
    .cat--unit__nav {
        margin: 25px -12.5px 25px;
        .slick-slide {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 150px;
            background-color: #fff;
            outline: none;
            padding: 15px;
            margin: 0px 12.5px;
            border: 1px solid #fff;
            transition: 300ms;
            cursor: pointer;
            img {
                max-height: 100%;
                max-width: 100%;
                object-fit: content;
                object-position: center center;
            }
            &:hover, &.slick-current {
                border: 1px solid #0080ff;
            }
        }
    }
    .square--list {
        margin-top: 0;
        li {
            font-size: 16px;
            &:before {
                top: 7px;
            }
        }
    }
    .cat--unit__table {
        margin-bottom: 10px;
        tr {
            td {
                padding: 4px 10px 4px 0;
                strong {
                    font-weight: normal;
                    color: #777777;
                }
                &:nth-of-type(1) {
                    padding-right: 40px;
                }
            }
        }
        &.half {
            width: 45%;
            tr {
                td {
                    &:nth-of-type(2) {
                        min-width: 170px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .cat--unit__for {
            .slick-slide {
                height: 300px;
            }
        }
        .cat--unit__nav {
            .slick-slide {
                height: 120px;
            }
        }
        .cat--unit__left {
            width: 450px;
        }
        .cat--unit__right {
            width: calc(100% - 450px);
            padding-left: 15px;
        }
    }
    @media screen and (max-width: 991px) {
        .cat--unit__for {
            .slick-slide {
                height: 250px;
            }
        }
        .cat--unit__nav {
            .slick-slide {
                height: 80px;
            }
        }
        .cat--unit__left {
            width: 300px;
        }
        .cat--unit__right {
            width: calc(100% - 300px);
        }
    }
    @media screen and (max-width: 580px) {
        .cat--unit__left {
            width: 100%;
        }
        .cat--unit__right {
            width: 100%;
            padding-left: 0;
        }
        .cat--unit__table {
            tr {
                td:first-child {
                    padding-right: 20px;
                }
            }
            &.half {
                width: 100%;
                tr {
                    td:nth-of-type(2) {
                        min-width: 0px;
                    }
                }
            }
        }
    }
}

.download {
    display: table;
    color: #0080ff;
    text-decoration: underline;
    margin: 10px 0 0 0;
    &:hover {
        text-decoration: none;
    }
}
/* page catalog */

/* page pagination */
.pagination {
    width: 100%;
    background-color: #ffffff;
    padding: 10px 10px 5px;
    margin: 20px 0 20px 0;
    border-radius: 0px;
    ul {
        display: flex;
        flex-wrap: wrap;
        @include ul-default;
        li {
            margin: 0px 0px 5px 0;
            a {
                display: block;
                width: 30px;
                height: 30px;
                font-family: $bold;
                font-size: 16px;
                text-align: center;
                color: #414141;
                position: relative;
                padding: 5px 2px;
                &:hover {
                    background-color: #0069d1;
                    color: #fff;
                }
            }
            &.active a {
                background-color: #0069d1;
                color: #fff;
            }
            &.prev, &.next {
                a {
                    &:after {
                        content: '\f101';
                        font-family: 'fontAwesome';
                        font-size: 16px;
                        color: #282a34;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 45%;
                        transform: translateY(-50%);
                    }
                    &:hover {
                        &:after {
                            color: #fff;
                        }
                    }
                }
            }
            &.prev a:after {
                content: '\f100';
            }
        }
    }
    @media screen and (max-width: 1199px) {
        margin: 10px 0 0;
        ul li {
            &.prev, &.next {
                a:after {
                    top: 50%;
                }
            }
        }
    }
}
/* page pagination */

/* page contacts */
.map {
    height: 450px;
    > ymaps, .ymaps-2-1-72-map {
        height: 100%!important;
    }
    @media screen and (max-width: 1199px) {
        height: 400px;
    }
    @media screen and (max-width: 991px) {
        height: 350px
    }
    @media screen and (max-width: 768px) {
        height: 300px;
    }
    @media screen and (max-width: 600px) {
        height: 250px;
    }
}
.contacts {
    width: 1220px;
    background-color: #f7f7f7;
    position: relative;
    padding: 50px 40px 0px;
    margin: -100px auto 0;
    z-index: 1;
    .contacts__block {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 50px;
        .col--location, 
        .col--phone,
        .col--email {
            padding-left: 30px;
            &:after {
                font-size: 20px;
                color: #0069d1;
            }
        }
        .col--phone {
            &:after {
                top: 1px;
            }
        }
        .col--email {
            &:after {
                font-size: 18px;
                top: 0;
            }
        }
        h5 {
            text-transform: uppercase;
            margin: 0 0 18px;
        }
        ul {
            @include ul-default;
        }
    }
    h2 {
        font-size: 32px;
        margin-bottom: 35px;
    }
    form {
        display: block;
        .g-recaptcha {
            @include inline-block;
            margin: 10px 40px 0 150px;
        }
        .btn {
            @include inline-block;
            margin-top: 10px;
        }
    }
    @media screen and (max-width: 1220px) {
        width: 100%;
        h2 {
            font-size: 28px;
            margin-bottom: 25px;
        }
        form {
            .g-recaptcha {
                margin-left: 0;
            }
        }
    }
    @media screen and (max-width: 991px) {
        padding: 20px 15px 0;
        margin: 0;
        h2 {
            font-size: 24px;
            margin-bottom: 20px;
        }
        .contacts__block {
            margin-bottom: 25px;
            h5 {
                font-size: 16px;
                margin-bottom: 10px;
            }
            .col--phone:after {
                top: 0;
            }
        }
    } 
    @media screen and (max-width: 768px) {
        h2 {
            font-size: 22px;
        }
    }
    @media screen and (max-width: 680px) {
        .contacts__block {
            justify-content: flex-start;
            .col--location,
            .col--phone,
            .col--email {
                width: 100%;
                margin: 0 0 10px;
            }
        }
    }
    @media screen and (max-width: 460px) {
        form {
            .g-recaptcha {
                float: right;
                margin: 0 0 10px;
            }
            .btn {
                float: right;
            }
        }
    }
    @media screen and (max-width: 340px) {
        form {
            .g-recaptcha {
                transform: scale(0.9);
                transform-origin: center right;
            }
        }
    }
}
/* page contacts */

/* modal */
.modal {
    .modal-dialog {
        top: 45%;
        margin: 0 auto;
        transform: translateY(-50%)!important;
    }
    .modal-content {
        width: auto;
        max-width: 420px;
        border-radius: 0px;
        margin: 0 auto;
        h4 {
            font-family: $bold;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            padding: 0 15px;
            margin: 0;
        }
        form {
            display: block;
            padding-bottom: 130px;
            margin-top: 25px;
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                display: block;
                width: 100%;
                background-color: #fff;
                font-family: $regular;
                font-size: 16px;
                color: #7a7b81;
                border: none;
                border-bottom: 2px solid #7a7b81;
                padding: 8px 20px;
                line-height: 1;
                margin: 15px 0; 
                outline: none;
                &::placeholder {
                    color: #848484;
                }
            }
            .g-recaptcha {
                float: right;
            }
            .btn {
                height: 45px;
                float: right;
                padding: 13px 40px 12px!important;
                margin: 10px 0 0 0;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .modal-content {
            form {
                textarea, 
                input[type="text"], 
                input[type="email"], 
                input[type="search"], 
                input[type="password"] {
                    min-height: 40px;
                    padding: 10px 15px;
                    &::placeholder {
                        padding-top: 1px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .modal-content {
            h4 {
                font-size: 16px;
            }
            form {
                textarea, 
                input[type="text"], 
                input[type="email"], 
                input[type="search"], 
                input[type="password"] {
                    min-height: 40px;
                    padding: 10px 15px;
                }
            }
        }
    }
    @media screen and (max-width: 420px) {
        .modal-dialog {
            margin: 0 10px;
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .modal-content {
            form {
                .g-recaptcha {
                    transform: scale(0.85);
                    transform-origin: 100% 50%;
                }
            }
        }
    }
}


/* modal */

/* page content */