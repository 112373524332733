/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 7, 2019 */
@font-face {
    font-family: 'bebas_neuebold';
    src: url('../fonts/bebasneue_bold-webfont.eot');
    src: url('../fonts/bebasneue_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_bold-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_bold-webfont.woff') format('woff'),
         url('../fonts/bebasneue_bold-webfont.ttf') format('truetype'),
         url('../fonts/bebasneue_bold-webfont.svg#bebas_neuebold') format('svg');
    font-weight: normal;
    font-style: normal;

}

$regular: 'Arial';
$bold: 'Arial';
$light: 'Arial';
$bebas: 'bebas_neuebold';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $regular;
    background-color: #f7f7f7;
    color: #414141;
    padding-top: 145px;
	margin: 0;
    @media screen and (max-width: 991px) {
        padding-top: 80px;
    }
}

h1 {
	font-family: $regular;
	font-size: 34px;
    font-weight: bold;
	text-align: center;
	color: #282727;
	line-height: 1;
	margin: 0 0 40px;
	@media screen and (max-width: 991px) {
		font-size: 28px;
        margin-bottom: 25px;
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
        margin-bottom: 20px;
	}
}
h2 {
	font-family: $bold;
	font-size: 38px;
    font-weight: bold;
	color: #414141;
    margin: 0 0 40px;
	a {
		font-family: $bold;
		font-size: 38px!important;
		color: #282727;
	}
	@media screen and (max-width: 991px) {
		font-size: 28px;
        margin-bottom: 25px;
		a {
			font-size: 28px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 22px;
		margin-bottom: 20px;
        a {
			font-size: 22px!important;
		}
	}
}
h3 {
	font-family: $bold;
	font-size: 24px;
    font-weight: bold;
	color: #414141;
	@media screen and (max-width: 991px) {
		font-size: 22px;
	}
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $bold;
	font-size: 22px;
    font-weight: bold;
	color: #414141;
    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $bold;
	font-size: 18px;
    font-weight: bold;
	color: #414141;
}
h6 {
	font-family: $bold;
	font-size: 16px;
    font-weight: bold;
	color: #414141;
}
a {
	transition: 350ms;
	text-decoration: none;
    color: #414141;
	&:focus {
		color: #414141;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #0080ff;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"] {
	border-radius: 0px;
	-webkit-appearance: none;
    color: #414141;
    &::placeholder {
        color: #414141;
    }
}
.main, .page, .form {
	position: relative;
	flex: 1 0 auto;
    font-size: 18px;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        min-height: 50px;
        width: 100%;
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
		padding: 13px 15px;
	    border: 1px solid #6499cd;
        margin-bottom: 20px;
    }
    @media screen and (max-width: 991px) {
        font-size: 16px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            min-height: 40px;
            padding: 8px 15px;
        }
    }
}
.main {
	.container {
		padding-top: 75px;
		padding-bottom: 75px;
		@media screen and (max-width: 1170px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}
}
.btn {
	display: table;
    height: 50px;
	width: auto!important;
	background-color: #000000!important;
	font-size: 16px;
	color: #fff!important;
	cursor: pointer;
	border-radius: 0px;
	border: 1px solid #000000;
	padding: 15px 40px!important;
    line-height: 1;
	transition: 300ms;
	&.blue {
        background-color: #fff!important;
        border: 1px solid #0080ff!important;
        color: #0080ff!important;
        &:focus {
            background-color: #fff!important;
        }
        &:hover {
            background-color: #0080ff!important;
            color: #fff!important;
        }
    }
    &:focus, &:active {
		background-color: #000!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #0080ff!important;
		color: #fff!important;
        border: 1px solid #0080ff;
	}	
    @media screen and (max-width: 991px) {
        height: 40px;
        padding: 10px 25px!important;
    }
}

.btn--m {
    display: none;
    height: 40px!important;
    padding: 10px 20px!important;
    &.blue {
        background-color: #0080ff!important;
        color: #fff!important;
        box-shadow: 0px 0px 5px rgba(255,255,255,0.5);
    }
    @media screen and (max-width: 991px) {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
    }
}

.check--list {
	@include ul-default;
    margin: 15px 0;
	li {
		display: block;
		position: relative;
		font-size: 18px;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 0px;
		}
	}
    @media screen and (max-width: 991px) {
        li {
            font-size: 16px;
        }
    }
}

.square--list {
    @extend .check--list;
    li {
        position: relative;
        padding-left: 18px;
        &:before {
            content: '';
            width: 6px;
            height: 6px;
            background-color: #0069d1;
            position: absolute;
            left: 0;
            top: 8px;
        }
    }
    @media screen and (max-width: 991px) {
        li {
            &:before {
                top: 7px;
            }
        }
    }
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	font-size: 16px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #304fbf;
		position: absolute;
		top: -2px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 16px;
	top: 2px;
}

.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 25px;
        height: 3px;
        background-color: #0080ff;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        opacity: 1;
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}


/* header */

/* header logo */
.logo {
    @include inline-block;
    font-family: $bebas;
    font-size: 32px;
    text-transform: uppercase;
    color: #414141;
    line-height: 1;
}
/* header logo */

header {
    height: 145px;
    background-color: #fff;
    font-size: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 23px;
    z-index: 9;
    border-bottom: 1px solid #dddddd;
    .container {
        position: relative;
    }
    ul {
        @include ul-default;
    }

    /* header search */
    .search {
        @include inline-block;
        width: 365px;
        padding-right: 45px;
        margin-left: 75px;
        position: relative;
        input[type="search"] {
            height: 45px;
            width: 100%;
            padding: 13px 15px;
            border: 1px solid #dddddd;
            border-right: none;
            outline: none;
            line-height: 1;
            box-sizing: border-box;
        }
        button {
            width: 45px;
            height: 45px;
            background-color: #000000;
            font-family: 'fontAwesome';
            font-size: 16px;
            color: #fff;
            border: none;
            position: absolute;
            right: 0;
            top: 0;
            padding: 0;
            transition: 300ms;
            &:after {
                content: '\f002';
                margin-top: -2px;
            }
            &:hover {
                background-color: #0080ff;
            }
        }
    }
    /* header search */

	/* header contacts */
	.cts {
        @include inline-block;
        float: right;
        text-align: right;
        margin-top: -5px;
        p {
            margin: 0;
        }
        ul {
            li {
                @include inline-block;
                position: relative;
                &:after {
                    content: '/';
                    @include inline-block;
                    margin: 3px 0 0 5px;
                }
                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
	/* header contacts */

    /* header nav */
    .nav {
        margin: 15px 0 0 0;
        > ul > li {
            @include inline-block;
            position: relative;
            margin-right: 48px;
            > a {
                display: block;
                position: relative;
                font-weight: bold;
                font-size: 18px;
                padding: 0 0 17px;
            }
            &.active > a {
                color: #0080ff;
            }
            &.submenu {
                position: relative;
                > a {
                    padding-right: 17px;
                    &:after {
                        content: '\f107';
                        font-family: 'fontAwesome';
                        font-size: 16px;
                        color: #414141;
                        position: absolute;
                        right: 0;
                        top: 4px;
                        transition: 300ms;
                    }
                    &:hover {
                        &:after {
                            color: #0080ff;
                            transform: rotate(-180deg); 
                        }
                    }
                }
                ul {
                    display: none;
                    background-color: #fff;
                    position: absolute;
                    top: 100%;
                    left: -20px;
                    padding: 5px 20px;
                    line-height: 1;
                    border: 1px solid #ddd;
                    z-index: 1;
                    li {
                        margin: 16px 0;
                        a {
                            font-size: 18px;
                            white-space: nowrap;
                        }
                        &.active a {
                            color: #0080ff;
                        }
                    }
                }
                &.hover {
                    > a:after {
                        color: #0080ff;
                        transform: rotate(-180deg);
                    }
                }
            }
        }
    }
    /* header nav */

	.blue {
        position: absolute;
        right: 15px;
        top: 55px;
    }
	
    @media screen and (max-width: 1199px) {
        .nav > ul > li {
            margin-right: 30px;
        }
    }   

	@media screen and (max-width: 991px) {
        height: 80px;
        padding-top: 10px;
        .logo {
            display: table;
            font-size: 26px;
            margin: 3px auto 0;
        }
        .search {
            display: none;
            width: 100%;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            margin: 0;
            z-index: 1;
        }
        .cts {
            display: none;
            background-color: rgba(65, 65, 65, 1);
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            text-align: center;
            color: #fff;
            padding: 15px;
            z-index: 2;
            p {
                margin-bottom: 5px;
            }
            ul li a {
                color: #fff;
            }
        }
        .cts--btn {
            font-family: 'fontAwesome';
            font-size: 22px;
            color: #414141;
            position: absolute;
            top: 15px;
            right: 15px;
            &:after {
                content: '\f095';
            }
            &:hover {
                text-decoration: none;
            }
        }
        .search--btn {
            font-family: 'fontAwesome';
            font-size: 22px;
            color: #414141;
            position: absolute;
            top: 15px;
            right: 45px;
            &:after {
                content: '\f002';
            }
            &:hover {
                text-decoration: none;
            }
        }
        .nav {
            display: none;
            max-height: calc(100% - 120px);
            background-color: #fff;
            position: fixed;
            left: 0;
            right: 0;
            top: 80px;
            padding: 5px 15px;
            border-top: 1px solid #0080ff;
            border-bottom: 1px solid #0080ff;
            margin: 0;
            overflow: auto;
            z-index: 1;
            > ul {
                width: 720px;
                margin: 0 auto;
                > li {
                    display: block;
                    margin: 10px 0;
                    > a {
                        display: table;
                        font-size: 16px;
                        padding-bottom: 0;
                    }
                    &.submenu {
                        a:after {
                            top: 3px;
                            transform: rotate(0deg)!important;
                        }
                        ul {
                            position: relative;
                            top: 0;
                            left: 0;
                            padding: 2px 10px;
                            margin: 5px 0;
                            li {
                                margin: 10px 0;
                                a {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 12px;
			top: 13px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #414141;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
        .btn {
            display: none;
        }
	}
    @media screen and (max-width: 480px) {
        .cts {
            ul li {
                display: block;
                &:after {
                    display: none;
                }
            }
        }
    }
}


/* header */