/* footer */
footer {
    background-color: #222222;
    padding-top: 60px;
    font-size: 16px;
    color: #fff;
    ul {
        @include ul-default;
        li {
            margin: 0 0 12px;
            &.active a {
                color: #0080ff;
            }
        }
    }
    h6 {
        color: #0080ff;
        text-transform: uppercase;
        margin: 0 0 30px;
    }
    a {
        color: #fff;
        &:focus {
            color: #fff;
        }
        &:hover {
            color: #0080ff!important;
        }
    }
    .flext--container {
        display: flex;
        width: 1170px;
        padding: 0 15px;
        justify-content: space-between;
        margin: 0 auto;
    }
    .footer__col {
        padding-right: 10px;
        &.footer--about {
            width: 220px;
            color: #fff;
            .logo {
                font-size: 40px;
                color: #ffffff;
                &:hover {
                    color: #0080ff;
                }
            }
        }
        .socials {
            display: flex;
            align-items: center;
            padding-top: 30px;
            margin-top: 20px;
            border-top: 1px solid #a7a7a7;
            li {
                margin-right: 45px;
                a {
                    font-size: 24px;
                }
            }
        }
        &.footer--cts {
            padding-right: 0;
            ul li {
                margin-bottom: 20px;
            }
            a {
                display: table;
            }
        }
    }
    .container-fluid {
        .container {
            padding: 30px 15px;
            margin-top: 35px;
            position: relative;
            &:after {
                content: '';
                display: block;
                height: 1px;
                width: auto;
                background-color: #a7a7a7;
                position: absolute;
                left: 15px;
                right: 15px;
                top: 0;
            }
        }
        p {
            @include inline-block;
            margin: 0;
        }
        .artmedia {
            @include inline-block;
            float: right;
            position: relative;
            padding-right: 130px;
            img {
                position: absolute;
                right: 0;
                top: -3px;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .flext--container {
            width: 970px;
        }
    }
    @media screen and (max-width: 991px) {
        font-size: 14px;
        padding-top: 40px;
        padding-bottom: 40px;
        .flext--container {
            width: 750px;
            flex-wrap: wrap;
        }
        .container-fluid {
            .container {
                padding: 20px 15px;
                margin-top: 15px;
            }
        }
        .footer__col {
            width: 50%;
            margin-bottom: 15px;
            &.footer--about {
                .socials {
                    padding-top: 15px;
                    margin-top: 10px;
                }
            }
            &.footer--cts {
                ul li {
                    margin-bottom: 10px;
                }
            }
        }
        ul li {
            margin-bottom: 8px;
        }
        h6 {
            margin: 0 0 20px;
        }
    }
    @media screen and (max-width: 767px) {
        padding-top: 25px;
        .flext--container {
            width: 100%;
        }
        .container-fluid .container {
            padding: 15px;
            margin-top: 15px;
        }
    }
    @media screen and (max-width: 600px) {
        .container-fluid {
            p {
                display: block;
                text-align: center;
            }
            .artmedia {
                display: table;
                float: none;
                margin: 10px auto 5px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .flext--container {
            justify-content: flex-start;
        }
        .footer__col {
            width: 100%;
        }
        .container-fluid .container {
            margin-top: 0;
        }
        h6 {
            margin: 0 0 10px 0;
        }
        .footer__col {
            &.footer--nav {
                display: none;
            }
        }
    }
}
/* footer */