/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}


/* main slider */
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.3);
        }
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		.slider__title {
            font-weight: bold;
            font-size: 46px;
            color: #ffffff;
			position: absolute;
			left: 30px;
			right: 30px;
			top: 50%;
			text-align: center;
			color: #fff;
            transform: translateY(-50%);
            line-height: 1.2;
            z-index: 1;
		}
	}
	.slick-dots {
		bottom: 50px;
		li {
            width: auto;
            height: auto;
			margin: 0px 5px;
			button {
				width: 40px;
				height: 5px;
				background-color: #fff;
                padding: 0;
                transition: 300ms;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #0080ff;
				}
			}
			&.slick-active {
				button {
					background-color: #0080ff;
				}
			}
		}
	}
    @media screen and (max-width: 1199px) {
        .slick-slide {
            .slider__title {
                font-size: 36px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .slick-slide {
            .slider__title {
                font-size: 28px;
            }
        }
        .slick-dots {
            bottom: 20px;
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            .slider__title {
                font-size: 24px;
            }
        }
        .slick-dots {
            li button {
                width: 30px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .slider__title {
            font-size: 20px;
        }
        .slick-slide {
            img {
                height: 210px;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .slick-slide {
            .slider__title {
                font-size: 16px;
            }
        }
    }
}
/* main slider */

/* main blocks */
.blocks {
    display: flex;
    width: 1170px;
    padding: 80px 15px;
    margin: 0 auto;
    .blocks__col {
        display: flex;
        min-height: 540px;
        width: 260px;
        flex-direction: column;
        position: relative;
        margin: 0 10px;
        transition: 300ms;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        &.blocks--img {
            background-color: #fff;
            padding: 5px 5px 50px;
            justify-content: flex-end;
            a {
                display: block;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center center;
                }
            }
            &:hover {
                box-shadow: 0px 0px 12px rgba(0,0,0,0.2);
            }
        }
        &.blocks--cat {
            background-color: #fff;
            width: 300px;
            padding: 35px 25px 170px;
            > a {
                display: block;
                width: 195px;
                position: absolute;
                bottom: 45px; 
                left: 25px;
                right: 25px;
                margin: 0 auto;
                img {
                    max-width: 100%;
                }
            }
            &:hover {
                box-shadow: 0px 0px 12px rgba(0,0,0,0.2);
            }
        }
        &.blocks--cat--inside {
            width: 540px;
            flex-direction: row;
            flex-wrap: wrap;
            box-shadow: none;
            .cat--inside__col {
                width: calc(50% - 10px);
                margin: 0 20px 20px 0px;
                &:nth-of-type(even) {
                    margin-right: 0px;
                } 
                &:nth-of-type(4),
                &:nth-of-type(3) {
                    margin-bottom: 0;
                }

            }
        }
        h2 {
            margin-bottom: 20px;
        }
        ul {
            @include ul-default;
            li {
                padding: 12px 0;
                border-bottom: 1px solid #c6c6c6;
                &:last-of-type {
                    border-bottom: none;
                }
                a {
                    font-weight: bold;
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        width: 970px;
        padding: 60px 15px;
        .blocks__col {
            &.blocks--img {
                padding-bottom: 25px;
            }
            &.blocks--cat {
                > a {
                    width: auto;
                    max-width: 100%;
                    bottom: 20px;
                }
            }
            &.blocks--cat {
                padding-bottom: 150px;
            }
            h2 {
                font-size: 30px;
            }
            ul li a {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        width: 750px;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 50px 15px;
        .blocks__col {
            width: calc(50% - 10px);
            min-height: 0px;
            &.blocks--cat {
                width: calc(50% - 10px);
                padding-bottom: 15px;
                margin-right: 0px;
                > a {
                    position: relative;
                    bottom: 0;
                    left: 0;
                    margin-top: 10px;
                }
            }
            &.blocks--cat--inside {
                width: 100%;
                margin: 20px 0 0;
            }
            h2 {
                font-size: 24px;
                margin-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 35px 15px;
    }
    @media screen and (max-width: 480px) {
        padding-bottom: 25px;
        .blocks__col {
            width: 100%;
            margin: 0 0 20px;
            &.blocks--cat {
                width: 100%;
                > a {
                    margin: 10px 0;
                }
            }
            &.blocks--cat--inside {
                margin: 0;
                .cat--inside__col {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 20px!important;
                }
            }
        }
    }
}

/* main blocks */

/* main about */
.about {
    position: relative;
    font-size: 16px;
    color: #fff;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
    }
    > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        left: 0;
    }
    .container {
        position: relative;
        z-index: 1;
    }
    h1, h2 {
        color: #fff;
        text-align: left;
        a {
            color: #fff;
        }
    }
    .about__block {
        width: 320px;
    }
    @media screen and (max-width: 991px) {
        .about__block {
            width: 100%;
        }
    }
}
/* main about */

/* main news */
.news {
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &:before, &:after {
            display: none;
        }
    }
    h2 {
        width: 100%;
        text-align: center;
    }
    .news__block {
        width: calc(50% - 20px);
        font-size: 16px;
        color: #414141;
        margin: 0px 0px 50px;
        .news__block--img {
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                transition: 300ms;
            }
        }
        .news__block--title {
            background-color: #fff;
            position: relative;
            padding: 20px;
            margin: -40px 0 0 70px;
            z-index: 1;
            transition: 300ms;
            .date {
                font-size: 16px;
                color: #868585;
            }
            h6 {
                text-transform: uppercase;
                margin: 20px 0 10px;
                transition: 300ms;
            }
        }
        &:hover {
            .news__block--img img {
                transform: scale(1.1);
            }
            .news__block--title {
                box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
                h6 {
                    color: #0080ff;
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        .news__block {
            .news__block--title {
                h6 {
                    font-size: 14px;
                    margin: 10px 0;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .news__block {
            .news__block--title {
                margin: 0;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .container {
            justify-content: flex-start;
        }
        .news__block {
            width: 100%;
            margin-bottom: 25px;
        }
    }
}
/* main news */

/* main form */
.form {
    background-color: #0069d1;
    color: #fff;
    margin-top: 85px;
    .container {
        padding: 50px 15px 40px;
    }
    h2 {
        font-size: 30px;
        color: #fff;
        text-align: center;
        margin-bottom: 25px;
    }
    p {
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        margin-bottom: 35px;
    }
    form {
        display: block;
        position: relative;
        padding-bottom: 90px;
    }
    input[type="text"] {
        @include inline-block;
        width: calc(40% - 30px);
        margin-right: 27px;
        margin-bottom: 0;
    }
    .g-recaptcha {
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .btn {
        @include inline-block;
        float: right;
        &:hover {
            background-color: #fff!important;
            border: 1px solid #fff;
            color: #0080ff!important;
        }
    }
    @media screen and (max-width: 1199px) {
        input[type="text"] {
            margin-right: 15px;
        }
        h2 {
            margin-bottom: 20px;
        }
        p {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 991px) {
        margin-top: 50px;
        .container {
            padding: 35px 15px 30px;
        }
        h2 {
            font-size: 26px;
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
        }
        input[type="text"] {
            width: calc(37% - 15px);
        }
    }
    @media screen and (max-width: 768px) {
        margin-top: 35px;
        h2 {
            font-size: 22px;
        }
    }
    @media screen and (max-width: 680px) {
        form {
            padding-bottom: 0;
        }
        input[type="text"] {
            width: 100%;
            margin: 0 0 15px;
        }
        .g-recaptcha {
            position: relative;
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 480px) {
        .btn {
            width: 100%!important;
        }
        .g-recaptcha {
            transform: scale(0.95);
            transform-origin: center right;
            float: right;
        }
    }
}
.main .form {
    margin: 0;
}
/* main form */

/* main content */